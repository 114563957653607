import React from 'react'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import Mixpanel from 'common/services/Mixpanel'
import Toast from 'components/Toast/Toast'
import TextArea from 'components/Form/TextArea/TextArea'

import './CodeBox.scss'

const CodeBox = ({ title, value, isVisbile = true, rows = 6, wrapLines = false }) => {
    const { t } = useTranslation()
    const handleCopyEmbedCode = () => {
        copy(value)
        Mixpanel.track('Embed code copied', {})
        Toast('success', {
            title: t('Code copied!'),
            time: 2000,
        })
    }
    if (!isVisbile) return null

    return (
        <div className="code-box">
            <p className="code-box__block-title">{title}</p>
            <div className="code-box__block-content">
                <div className="code-box__code">
                    <div className="code-box__code-textarea">
                        <TextArea rows={rows} value={value} isReadOnly wrapLines={false} />
                    </div>
                    <button
                        className="button code-box__code-button"
                        data-variant="primary"
                        data-size="lg"
                        onClick={handleCopyEmbedCode}
                    >
                        {t('Copy code')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CodeBox
