import React, { useState, useEffect, useRef } from 'react'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode.react'

import { isBlockedMeta } from 'utils/common'

import { isEducation } from 'common/utils/permissions'
import Toast, { dismiss } from 'components/Toast/Toast'
import Mixpanel from 'common/services/Mixpanel'
import { isEuVersion } from 'common/constants/env'

import { API__PROJECTS } from 'api'

import useModalManager from 'hooks/useModalManager'

import { MODALS } from 'components/ModalManager/constants'
import CircleSpinner from 'components/CircleSpinner/CircleSpinner'

import { SHARE_MODAL_TABS, SOCIAL_BLOCKS } from '../../utils/constants'

import shareIcon from './i/share.svg'
import printIcon from './i/print.svg'
import embedIcon from './i/embed.svg'
import qrIcon from './i/qr.svg'
import communityIcon from './i/community.svg'
import embedManIcon from './i/embedMan.svg'

import ShareUrlBody from './components/ShareUrlBody/ShareUrlBody'
import PrintBody from './components/PrintBody/PrintBody'
import PrintProcessingBody from './components/PrintProcessingBody/PrintProcessingBody'
import WideBox from './components/WideBox/WideBox'
import CompactBox from './components/CompactBox/CompactBox'
import SocialMedia from './components/SocialMedia/SocialMedia'

import styles from './Main.module.scss'

const Main = ({ projectAuthor, projectUrl, project, setTab, onOpenCreateTemplateModal }) => {
    const { openModal } = useModalManager()
    const generatePdfToastId = useRef(null)
    const { t } = useTranslation()
    const user_details = useSelector(state => state.user_details)
    const [isCanCreateTemplate, setIsCanCreateTemplate] = useState(false)
    const [isPdfLoading, setIsPdfLoading] = useState(false)

    useEffect(() => {
        if (!isEducation() || isEuVersion) {
            setIsCanCreateTemplate(false)
            return
        }
        setIsCanCreateTemplate(user_details.id === projectAuthor.userId)
    }, [user_details.id, projectAuthor.userId, isEuVersion])

    if (isBlockedMeta()) delete SOCIAL_BLOCKS.facebook

    const handleCopyProjectUrl = () => {
        copy(projectUrl)
        Mixpanel.track('Project url copied', { url: projectUrl })
        Toast('success', {
            title: t('Link copied!'),
            time: 2000,
        })
    }

    const handleGetProjectPdf = async () => {
        try {
            const fileName = `${project.name}.pdf`
            generatePdfToastId.current = Toast('info', {
                title: t('Generating PDF'),
                message: fileName,
                IconComponent: <CircleSpinner />,
                time: 999999,
            })
            setIsPdfLoading(true)

            const url = await API__PROJECTS.GET_PROJECT_PDF(project.projectId, project.name)

            openModal(MODALS.PDF_GENERATION, { url, fileName })
        } catch (err) {
            console.error(err)
            Toast('error', {
                title: t('Error when creating a PDF file of the project'),
                time: 2000,
            })
        } finally {
            setIsPdfLoading(false)
            dismiss(generatePdfToastId.current)
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.wideCards}>
                <WideBox
                    title={isEducation() ? t('Share with students') : t('Direct URL to your Project')}
                    image={shareIcon}
                    body={<ShareUrlBody projectUrl={projectUrl} onCopyProjectUrl={() => handleCopyProjectUrl()} />}
                />
                <WideBox
                    title={!isPdfLoading && t('Convert to PDF and print')}
                    isNewLabel
                    image={printIcon}
                    body={
                        isPdfLoading ? (
                            <PrintProcessingBody />
                        ) : (
                            <PrintBody projectUrl={projectUrl} onGetProjectPdf={() => handleGetProjectPdf()} />
                        )
                    }
                />
            </div>

            <div className={styles.compactCards}>
                {isEducation() ? (
                    <CompactBox
                        width={isCanCreateTemplate ? 'calc(33.33% - 8px)' : 'calc(50% - 8px)'}
                        title={t('Embed in LMS')}
                        icon={embedIcon}
                        text={t('Embed in your website or school LMS')}
                        onClick={() => setTab(SHARE_MODAL_TABS.embedCode)}
                    />
                ) : (
                    <CompactBox
                        width={'calc(50% - 8px)'}
                        title={t('Embed in a website')}
                        icon={embedIcon}
                        additionalBlock={<img src={embedManIcon} alt="" />}
                        text={t('Get iframe embed code options')}
                        onClick={() => setTab(SHARE_MODAL_TABS.embedCode)}
                    />
                )}
                <CompactBox
                    width={isCanCreateTemplate ? 'calc(33.33% - 8px)' : 'calc(50% - 8px)'}
                    title={t('Get QR code')}
                    icon={qrIcon}
                    additionalBlock={<QRCode value={projectUrl} size={isEducation() ? 60 : 100} />}
                    text={t('For a quick start on the phone')}
                    onClick={() => setTab(SHARE_MODAL_TABS.qrCode)}
                />
                {isCanCreateTemplate && (
                    <CompactBox
                        width={'calc(33.33% - 8px)'}
                        title={t('Share with the community')}
                        icon={communityIcon}
                        readMoreLink="https://help.interacty.me/en/content/how-to-create-a-public-template-based-on-your-project"
                        onClick={() => onOpenCreateTemplateModal()}
                    />
                )}
            </div>

            <SocialMedia projectUrl={projectUrl} />
        </div>
    )
}

export default Main
