import React from 'react'
import qs from 'qs'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'

import Mixpanel from 'common/services/Mixpanel'

import Toast from 'components/Toast/Toast'

import withInitialLoading from 'hocs/withInitialLoading'

import { API__PAYMENTS, API__USER } from 'api'

import { addRouterQueryParam, removeRouterQueryParam } from 'utils/router'

import { USER__SET_SUBSCRIPTION, USER__SET_SUBSCRIPTION_CONSTRAINTS } from 'store/actions'

import Subscription from './views/Subscription/Subscription'
import BillingHistory from './views/BillingHistory/BillingHistory'

import PageBar from './components/PageBar/PageBar'

import { SUBSCRIPTION_TABS } from './constants'

import './BillingAndPayments.scss'

class BillingAndPayments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: SUBSCRIPTION_TABS.subscription,
            tabs: Object.values(SUBSCRIPTION_TABS),

            paymentsLoading: false,
            payments: {
                pagination: {
                    initialPage: 0,
                    perPage: 100,
                    totalPages: 0,
                    offsetPages: 0,
                },
                filter: {
                    sort: 'saleDate,desc',
                },
                content: [],
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        try {
            const { tabs } = this.state

            const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
            const queryParamsTab = queryParams.tab

            if (tabs.indexOf(queryParamsTab) !== -1) {
                await this.setState({
                    tab: queryParamsTab,
                })
            } else {
                removeRouterQueryParam('tab', {
                    history: this.props.history,
                    location: this.props.location,
                })
            }
            const { user, P__USER__SET_SUBSCRIPTION, P__USER__SET_SUBSCRIPTION_CONSTRAINTS, onReady } = this.props
            const subscription = await API__USER.GET_SELECTED_SUBSCRIPTION(user.id)
            await P__USER__SET_SUBSCRIPTION(subscription)

            const constraints = await API__USER.GET_SUBSCRIPTION_CONSTRAINTS(subscription.id)
            await P__USER__SET_SUBSCRIPTION_CONSTRAINTS(constraints)

            const { tab } = this.state

            if (tab === SUBSCRIPTION_TABS.billingHistory) {
                await this.updatePaymentsHistory(true)
            } else {
                Mixpanel.track('Pricing Opened', {})
            }

            onReady()
        } catch (err) {
            console.error(err)
        }
    }

    async updatePaymentsHistory(clear) {
        try {
            await this.setState({ paymentsLoading: true })

            if (clear) {
                await this.clearPaymentsHistory()
            }

            const {
                payments: { pagination, filter },
            } = this.state

            // Pagination
            const params = {
                perPage: pagination.perPage,
                offsetPages: pagination.offsetPages,
                sort: filter.sort,
            }

            const response = await API__PAYMENTS.GET_PAYMENTS_HISTORY(params)

            await this.setState(prevState => ({
                payments: {
                    ...prevState.payments,
                    content: [...prevState.payments.content, ...response.content],
                    pagination: {
                        ...prevState.payments.pagination,
                        totalPages: response.totalPages,
                    },
                },
            }))
        } finally {
            await this.setState({ paymentsLoading: false })
        }
    }

    clearPaymentsHistory = async () => {
        await this.setState(prevState => ({
            payments: {
                ...prevState.payments,
                content: [],
                pagination: this.baseState.payments.pagination,
            },
        }))
    }

    handleTabClick = target => async evt => {
        evt.preventDefault()
        const { tab } = this.state
        if (target !== tab) {
            if (target === SUBSCRIPTION_TABS.subscription) {
                removeRouterQueryParam('tab', {
                    history: this.props.history,
                    location: this.props.location,
                })
            } else {
                addRouterQueryParam('tab', target, {
                    history: this.props.history,
                    location: this.props.location,
                })
            }
            await this.setState({
                tab: target,
            })

            if (target === SUBSCRIPTION_TABS.billingHistory) {
                await this.updatePaymentsHistory(true)
            }
        }
    }

    getInvoice = async item => {
        const { t } = this.props
        try {
            await API__PAYMENTS.GET_INVOICE(item.externalOrderReference)
        } catch (err) {
            Toast('error', {
                title: t('Failed...'),
                message: t('Invoice not Found'),
            })
        }
    }

    render() {
        const { t, isReady } = this.props
        const { tab, payments, paymentsLoading } = this.state

        return (
            <div className="page page--billing_and_payments" data-structure="1">
                <Helmet>
                    <title>{t('Billing and payments')} | Interacty</title>
                </Helmet>
                {isReady && (
                    <section>
                        <div className="container">
                            <PageBar currentTab={tab} handleTabClick={this.handleTabClick} />
                            {tab === SUBSCRIPTION_TABS.subscription && <Subscription />}
                            {tab === SUBSCRIPTION_TABS.billingHistory && (
                                <BillingHistory
                                    isLoading={paymentsLoading}
                                    payments={payments}
                                    getInvoice={this.getInvoice}
                                />
                            )}
                        </div>
                    </section>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user_details,
        user_subscription: state.user_subscription,
        user_subscription_constraints: state.user_subscription_constraints,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        P__USER__SET_SUBSCRIPTION: obj => dispatch(USER__SET_SUBSCRIPTION(obj)),
        P__USER__SET_SUBSCRIPTION_CONSTRAINTS: obj => dispatch(USER__SET_SUBSCRIPTION_CONSTRAINTS(obj)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('translations'),
    withInitialLoading,
)(BillingAndPayments)
