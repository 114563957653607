export const ALL_PROJECTS_MEMBER = {
    isApprove: true,
    name: 'All projects',
    userId: null,
}

export const REDIRECT_ACTIONS = {
    editorPublished: 'editor--published',
    editorMultiplayerPublished: 'editor-multiplayer--published',
    reachedQuotaPremiumTemplates: 'reached-quota--premium-templates',
    selectedOrganizationSubscriptionExpired: 'selected-organization-subscription-expired',
    projectLimitExceeded: 'project-limit-exceeded',
}
