import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { USER__SET_DETAILS } from 'store/actions'

import useRouter from 'hooks/useRouter'
import { sendGoogleAnalyticsEvent } from 'hooks/useGoogleAnalytics'

import Mixpanel from 'common/services/Mixpanel'

import Toast from 'components/Toast/Toast'

import { SOCIAL_COOKIE } from 'api/auth'
import { GET_DETAILS } from 'api/user'

const OAuth = () => {
    const { t } = useTranslation()
    const { queryParams, historyPush } = useRouter()
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            if (queryParams.error) {
                handleError(queryParams.error)
                return
            }

            SOCIAL_COOKIE(queryParams.token, queryParams.id)
                .then(() => {
                    GET_DETAILS()
                        .then(user => {
                            dispatch(USER__SET_DETAILS(user))
                            sendGoogleAnalyticsEvent('signin')
                            Mixpanel.identify(queryParams.id)

                            if (queryParams.is_reg === 'true') {
                                sendGoogleAnalyticsEvent('registration')
                                Mixpanel.track('User registered', {
                                    email: queryParams.login,
                                    provider: queryParams.provider,
                                })
                                Mixpanel.track('User activated', {})
                            }
                        })
                        .catch(err => handleError(err))
                })
                .catch(err => handleError(err))
        } catch (err) {
            handleError(err)
        }
    }, [])

    const handleError = err => {
        console.error(err)
        Toast('error', { title: t('Authorization error') })
        historyPush('/auth')
    }

    return null
}

export default OAuth
