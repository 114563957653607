import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { API__USER } from 'api'

import Toast from 'components/Toast/Toast'

import Mixpanel from 'common/services/Mixpanel'

import EMAILS from 'common/constants/emails'

import checkPromoImage from './i/checkPromo.png'

import { MAX_RESEND_ATTEMPTS } from './constants'

import styles from './NotSeeEmail.module.scss'

const NotSeeEmail = ({ email }) => {
    const { t } = useTranslation()
    const [resendAttempts, setResendAttempts] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className={styles.notSeeEmail}>
            <h4 className={styles.title}>{t("Don't see the letter?")}</h4>
            <p className={styles.description}>{t('Check the Promotions section or your spam folder')}</p>
            <div className={styles.image}>
                <img src={checkPromoImage} alt="" />
            </div>
            <div className={styles.buttons}>
                <button
                    data-test="resend-activation-email"
                    className="button"
                    data-size="lg"
                    data-variant="primary-light"
                    disabled={resendAttempts >= MAX_RESEND_ATTEMPTS || isLoading}
                    onClick={() => {
                        setIsLoading(true)
                        API__USER.RESEND_ACTIVATION_LINK(email)
                            .then(() => {
                                setResendAttempts(resendAttempts + 1)
                                Mixpanel.track('Activation link resended', { email })
                                Toast('success', {
                                    message: t('Confirmation link resubmitted'),
                                })
                            })
                            .catch(err => {
                                console.error(err)
                                Toast('error', {})
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    }}
                >
                    {t('Resend email')}
                </button>
                <button
                    className="button"
                    data-size="lg"
                    data-variant="primary-light"
                    onClick={() => (window.location.href = `mailto:${EMAILS.INTERACTY_SUPPORT}`)}
                >
                    {t("I still can't see the confirmation email")}
                </button>
            </div>
        </div>
    )
}

export default NotSeeEmail
