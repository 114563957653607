import React, { useEffect, useState } from 'react'

import Mixpanel from 'common/services/Mixpanel'

import SideNav from './components/SideNav/SideNav'
import Footer from './components/Footer/Footer'

import styles from './Body.module.scss'

const Body = ({
    sideNav: { items = [], activeItemId = null, onChangeItem = () => {}, analyticsBlockName = null } = {},
    onSave,
    children,
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(-1)
    const [sentTabNumbers, setSentTabNumbers] = useState([])

    useEffect(() => {
        setActiveTabIndex(items.findIndex(item => item.id === activeItemId))
    }, [activeItemId, items])

    useEffect(() => {
        onSendNavChanged(activeItemId)
    }, [activeItemId, analyticsBlockName])

    const onSendNavChanged = tabNumber => {
        if (!analyticsBlockName) return
        if (sentTabNumbers.includes(tabNumber)) return
        Mixpanel.track('Click Edit Modal Tab', {
            blockType: analyticsBlockName,
            tabNumber,
        })
        setSentTabNumbers([...sentTabNumbers, tabNumber])
    }

    return (
        <>
            <div className={styles.body}>
                {items?.length > 1 && <SideNav items={items} activeItemId={activeItemId} onChangeItem={onChangeItem} />}
                <div className={styles.content}>{children}</div>
            </div>
            <Footer
                onNext={activeTabIndex === items.length - 1 ? null : () => onChangeItem(items[activeTabIndex + 1].id)}
                onSave={onSave}
            />
        </>
    )
}

export default Body
