import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'

import Mixpanel from 'common/services/Mixpanel'

import { EDUCATION_GROUP_ID } from 'common/constants/ProductConstants'

const defineGroup = groupId => (groupId === EDUCATION_GROUP_ID ? 'Education' : 'Business')

const useMixpanel = () => {
    const { selectedOrganizationId, selectedSubscription, userDetails } = useSelector(state => ({
        selectedOrganizationId: state.organizations.selectedOrganizationId,
        selectedSubscription: state.organizations.selectedSubscription,
        userDetails: state.user_details,
    }))

    useEffect(() => {
        if (!isEmpty(userDetails)) {
            if (userDetails.isTest) Mixpanel.opt_out_tracking()

            const roleInProjectDto = userDetails.roleInProjectDto
            Mixpanel.register({
                group: roleInProjectDto && defineGroup(roleInProjectDto.userGroupInProjectDto.customId),
                role: roleInProjectDto && roleInProjectDto.id,
            })
        }
    }, [userDetails])

    useEffect(() => {
        if (selectedOrganizationId && !isEmpty(selectedSubscription))
            Mixpanel.register({
                organizationId: selectedOrganizationId,
                productCode: selectedSubscription.product.productCode,
            })
    }, [selectedOrganizationId, selectedSubscription])
}

export default useMixpanel
