import React from 'react'
import { withTranslation } from 'react-i18next'

import ErrorLogging from 'common/services/ErrorLogging'

import './ErrorBoundary.scss'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, errorInfo: '', error: '', showStack: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        ErrorLogging.log(error, { ...errorInfo, fromErrorBoundary: true })
        this.setState({ errorInfo })
    }

    onShowStack = () => {
        this.setState(prevState => ({ showStack: !prevState.showStack }))
    }

    render() {
        const { hasError, errorInfo, error, showStack } = this.state
        const { isShowReloadBtn, i18n } = this.props

        if (hasError) {
            return (
                <div className="error-boundary">
                    <div className="error-boundary__error-icon" />
                    <div className="error-boundary__error-title">{i18n.t('Ooops!')}</div>
                    <div className="error-boundary__error-message">
                        {i18n.t('An error occurred. Please try again later.')}
                    </div>
                    {isShowReloadBtn && (
                        <button
                            className="error-boundary__reload-btn"
                            onClick={() => {
                                window.location.reload()
                            }}
                        >
                            {i18n.t('Reload page')}
                        </button>
                    )}
                    <div className="error-boundary__details">
                        <div className="error-boundary__details-btn" onClick={this.onShowStack}>
                            {!showStack ? i18n.t('See details:') : i18n.t('Hide details:')}
                        </div>
                        <div className={`error-boundary__details-stack ${showStack ? 'is-show' : ''}`}>
                            <pre className="error-boundary__details-stack-content">
                                <div className="error-boundary__details-stack-title">TypeError: {error.message}</div>
                                {errorInfo.componentStack}
                            </pre>
                        </div>
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

export default withTranslation('translations')(ErrorBoundary)
