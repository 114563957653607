import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { cloneDeep, isEqual, set } from 'lodash'
import { Redirect, Route, Switch } from 'react-router-dom'
import qs from 'qs'
import classNames from 'classnames'

import Toast from 'components/Toast/Toast'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import SelectOrganization from 'components/Modal/SelectOrganization/SelectOrganization'

import withInitialLoading from 'hocs/withInitialLoading'

import GAMIFICATION_ACTION_TYPES from 'common/constants/GamificationActionTypes'
import { PUBLIC_PROFILE_STATISTIC_ACTIONS } from 'common/constants/publicProfile'
import { isBlockArticleType } from 'common/utils/isBlockArticleType.js'
import { encodeStringsInObject, decodeStringsInObject } from 'common/utils/objectStringsAction'
import Mixpanel from 'common/services/Mixpanel'
import { buildProjectUrl } from 'common/utils/project'
import projectModes from 'common/constants/projectModes'
import { Helmet } from 'react-helmet'

import { API__PROJECTS, HTTP_STATUS, API__USER } from 'api'

import { REDIRECT_ACTIONS } from 'pages/MyProjects/utils/constants'

import { getProjectEmbedCodeWithIFrame, getProjectEmbedCodeWithLoader } from 'utils/embed'
import getBlocksFromStructure from 'utils/getBlocksFromStructure'
import { getFullUrlToSsr } from 'utils/router'

import { ORGANIZATIONS__SET_ORGANIZATION_AS_SELECTED, PROJECTS__SET_PROJECT_SUBSCRIPTION } from 'store/actions'

import Normalizer from './Tabs/Editor/util/normalizer'
import DataSchema from './Tabs/Editor/util/schema'

import TopBar from './components/TopBar/TopBar'
import MultiplayerWelcome from './components/MultiplayerWelcome/MultiplayerWelcome'

import EditorTab from './Tabs/Editor'
import PreviewTab from './Tabs/Preview/Preview'
import IntegrationsTab from './Tabs/Integrations'
import SharingOptionsTab from './Tabs/SharingOptions/SharingOptions'
import { BLOCK_SCHEMA, APP_SCHEMA } from './Tabs/Editor/schema'
import GamificationPromo from './Tabs/Editor/Modals/GamificationPromo/GamificationPromo'
import { isShowGamificationPromo } from './Tabs/Editor/Modals/GamificationPromo/utils/utils'

import girlSitting from './i/girlSitting.svg'

import { ProjectContext } from './contexts/projectContext'

import styles from './Editor.module.scss'

const CONFIRMATION_DIALOG_TYPES = {
    emptyProject: 'emptyProject',
    emptyHomePage: 'emptyHomePage',
    premiumBlocks: 'premiumBlocks',
}
const AUTO_SAVE_INTERVAL = 20000 // 1000 = 1s

class Editor extends React.Component {
    autoSaveIntervalId
    _isMounted = false

    constructor(props) {
        super(props)
        this.state = {
            project: null,
            tmpProject: null,

            isMultiplayerGame: false,

            processes: {
                autoSaving: false,
                saving: false,
                publishing: false,
            },

            confirmationDialog: {
                isOpen: false,
                type: undefined, // publish, etc.
                payload: {},
            },

            isNewProject: false,
            isOpenGamificationPromo: false,
            isShowSelectOrganization: false,
            isShowMultiplayerWelcome: false,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        const {
            history,
            organizations: { selectedOrganizationId },
            SET_PROJECT_SUBSCRIPTION,
        } = this.props
        try {
            const { match } = this.props
            const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

            if (match.params.projectId) {
                const project = await API__PROJECTS.GET_PROJECT(match.params.projectId)
                SET_PROJECT_SUBSCRIPTION(project.subscription)
                await this.normalizeProject(project)
            } else {
                if (!queryParams.templateId) {
                    history.push('/')
                    return
                }
                if (!queryParams.organizationId) {
                    this.setState({ isShowSelectOrganization: true })
                    return
                }

                await this.createProject(queryParams.organizationId)
            }
        } catch (err) {
            this.errorCatcher(err, selectedOrganizationId)
        }
    }

    componentWillUnmount() {
        const { SET_PROJECT_SUBSCRIPTION } = this.props
        clearInterval(this.autoSaveIntervalId)
        SET_PROJECT_SUBSCRIPTION(null)
        this._isMounted = false
    }

    /**
     * Auto-saving
     */
    autoSave = async () => {
        if (!this._isMounted) return
        const { project, tmpProject } = this.state

        const projectToCheck = {}
        const tmpProjectToCheck = {}
        const fieldsToCheck = ['name', 'title', 'description']
        fieldsToCheck.forEach(el => {
            projectToCheck[el] = project[el]
            tmpProjectToCheck[el] = tmpProject[el]
        })
        projectToCheck['projectStructureJson'] = project['projectStructureJson']
        const clonedStructure = cloneDeep(tmpProject.projectStructureJson)
        tmpProjectToCheck['projectStructureJson'] = encodeStringsInObject(clonedStructure)

        if (isEqual(JSON.stringify(projectToCheck), JSON.stringify(tmpProjectToCheck))) return
        const { processes } = this.state
        if (!processes.autoSaving && !processes.saving && !processes.publishing) {
            console.log('Changes detected! Initialized auto-saving')
            try {
                await this.setState(prevState => ({
                    processes: {
                        ...prevState.processes,
                        autoSaving: true,
                    },
                }))

                await this.updateProject(false)
            } catch (err) {
                console.error(err)
                Toast('error', {
                    title: 'Autosaving error',
                    message: 'An error occurred while trying to autosave project',
                })
            } finally {
                await this.setState(prevState => ({
                    processes: {
                        ...prevState.processes,
                        autoSaving: false,
                    },
                }))
            }
        }
    }

    normalizeProject = async project => {
        const { user_details, onReady } = this.props
        const { isNewProject } = this.state

        const projectStructureJson = decodeStringsInObject(cloneDeep(project.projectStructureJson))

        projectStructureJson.app = new Normalizer(new DataSchema(APP_SCHEMA)).process(projectStructureJson.app)
        projectStructureJson.pages = projectStructureJson.pages.map(page => ({
            ...page,
            blocks: page.blocks.map(block => new Normalizer(new DataSchema(BLOCK_SCHEMA[block.t])).process(block)),
        }))

        await this.setState({
            project,
            tmpProject: {
                ...project,
                projectStructureJson,
            },
            isMultiplayerGame: project.mode === projectModes.MULTIPLAYER,
            isShowMultiplayerWelcome: isNewProject ? project.mode === projectModes.MULTIPLAYER : false,
        })

        onReady()

        if (!user_details.adminAuth) this.autoSaveIntervalId = setInterval(this.autoSave, AUTO_SAVE_INTERVAL)
    }

    errorCatcher = (error, organizationId) => {
        const { history } = this.props
        console.error(error)
        if (error.response) {
            const {
                response: { status, data },
            } = error
            if (
                status === HTTP_STATUS.Forbidden &&
                data.message === 'You reached monthly quota for create project from premium templates'
            ) {
                history.push('/my-projects', {
                    action: REDIRECT_ACTIONS.reachedQuotaPremiumTemplates,
                    data: { organizationId },
                })
                return
            } else if (status === HTTP_STATUS.PaymentRequired && data.message === 'Subscription expired') {
                history.push('/my-projects', {
                    action: REDIRECT_ACTIONS.selectedOrganizationSubscriptionExpired,
                    data: { organizationId },
                })

                return
            } else if (
                status === HTTP_STATUS.PaymentRequired &&
                data.message === 'Projects count exceeded for subscription'
            ) {
                history.push('/my-projects', {
                    action: REDIRECT_ACTIONS.projectLimitExceeded,
                    data: { organizationId },
                })

                return
            }
        }

        history.push('/my-projects')
    }

    createProject = async orgId => {
        const {
            history,
            organizations: { selectedOrganizationId, selectedFolderId },
            SET_ORGANIZATION_AS_SELECTED,
        } = this.props

        try {
            const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
            const templateId = queryParams.templateId
            const project = await API__PROJECTS.CREATE_PROJECT_FROM_TEMPLATE(templateId, orgId, selectedFolderId)
            Mixpanel.track('Project created', {
                templateId,
                projectId: project.id,
                source: 'gallery',
            })
            if (selectedOrganizationId !== orgId) SET_ORGANIZATION_AS_SELECTED(orgId)

            const { profileId, contentId } = queryParams
            if (profileId && contentId) {
                API__USER.SEND_PUBLIC_PROFILE_ACTION({
                    type: PUBLIC_PROFILE_STATISTIC_ACTIONS.TEMPLATE_CLONE,
                    profileId,
                    contentId,
                })
                    .then(() => {})
                    .catch(err => console.error(err))
            }

            history.push(`/editor/${project.projectId}`)
            await this.setState({ isNewProject: true })
            await this.normalizeProject(project)
        } catch (error) {
            this.errorCatcher(error, orgId)
        }
    }

    updateProject = async (setProcess = true) => {
        try {
            if (setProcess) {
                await this.setState(prevState => ({
                    processes: {
                        ...prevState.processes,
                        saving: true,
                    },
                }))
            }

            const { tmpProject } = this.state

            const updatedProject = await API__PROJECTS.UPDATE_PROJECT(tmpProject.projectId, {
                id: tmpProject.id,
                projectId: tmpProject.projectId,
                projectType: {
                    id: tmpProject.projectType.id,
                },
                projectStructureJson: encodeStringsInObject(cloneDeep(tmpProject.projectStructureJson)),
                name: tmpProject.name.trim(),
                title: tmpProject.title.trim(),
                description: tmpProject.description.trim(),
                customImageSelected: tmpProject.customImageSelected,
                faviconUrl: tmpProject.faviconUrl,
                optimizeForLargeDisplays: tmpProject.optimizeForLargeDisplays,
            })
            // TODO have to handle 402 error payment required
            await this.setState(prevState => ({
                project: updatedProject,
                tmpProject: {
                    ...prevState.tmpProject,
                    // Эти поля меняются только на сервере (список не полный, но конкрено эти нужно обновить в "tmpProject")
                    imageUrl: updatedProject.imageUrl,
                    customUrl: updatedProject.customUrl,
                },
            }))

            return updatedProject
        } finally {
            if (setProcess) {
                await this.setState(prevState => ({
                    processes: {
                        ...prevState.processes,
                        saving: false,
                    },
                }))
            }
        }
    }

    publishProject = async (force = false) => {
        try {
            const {
                project: { id, projectId },
                isMultiplayerGame,
            } = this.state
            const { history } = this.props

            await this.setState(prevState => ({
                processes: {
                    ...prevState.processes,
                    publishing: true,
                },
            }))

            if (!force && !(await this.checkIsCanUserPublish())) return

            const updatedProject = await this.updateProject(false)

            const payload = {}
            if (isMultiplayerGame) payload.roundsCount = updatedProject.projectStructureJson.pages.length
            await API__PROJECTS.PUBLISH(projectId, payload)
            await this.sendConstraintsGamificationBlocks()
            Mixpanel.track('Project published', { projectId: id })
            const publishedProject = await API__PROJECTS.GET_PROJECT(projectId)

            const projectVersion = publishedProject.projectVersions[0]
            const hashOfProject = projectVersion.versionHash
            const iframeCode = isBlockArticleType(publishedProject.projectType.projectType)
                ? getProjectEmbedCodeWithIFrame(hashOfProject)
                : ''

            if (isMultiplayerGame) {
                history.push('/my-projects', {
                    action: REDIRECT_ACTIONS.editorMultiplayerPublished,
                    data: {
                        project: publishedProject,
                    },
                })
            } else {
                history.push('/my-projects', {
                    action: REDIRECT_ACTIONS.editorPublished,
                    data: {
                        project: publishedProject,
                        projectUrl: buildProjectUrl(publishedProject.customUrl, hashOfProject),
                        embedCode: getProjectEmbedCodeWithLoader(hashOfProject),
                        iframeCode: iframeCode,
                    },
                })
            }
        } catch (err) {
            console.error(err)
            Toast('error', {
                title: 'Publishing error',
                message: 'An error occurred while trying to Publishing project',
            })
        } finally {
            await this.setState(prevState => ({
                processes: {
                    ...prevState.processes,
                    publishing: false,
                },
            }))
        }
    }

    checkIsCanUserPublish = async () => {
        const {
            tmpProject: { projectStructureJson },
            isMultiplayerGame,
        } = this.state

        const allBlocks = getBlocksFromStructure(projectStructureJson)

        if (projectStructureJson.hasOwnProperty('pages')) {
            const homePageHasBlocks = projectStructureJson.pages[0].blocks.length
            if (!homePageHasBlocks) {
                this.showConfirmationDialog(CONFIRMATION_DIALOG_TYPES.emptyHomePage, {})
                return false
            }
        }

        if (!allBlocks.length) {
            this.showConfirmationDialog(CONFIRMATION_DIALOG_TYPES.emptyProject, {})
            return false
        }

        if (!isMultiplayerGame && isShowGamificationPromo(allBlocks)) {
            this.setState({ isOpenGamificationPromo: true })
            return false
        }

        return true
    }

    sendConstraintsGamificationBlocks = async () => {
        const {
            project: { id },
            tmpProject: { projectStructureJson },
        } = this.state

        const allBlocks = getBlocksFromStructure(projectStructureJson)
        const blocksWithGamification = allBlocks.filter(el => {
            return Object.keys(GAMIFICATION_ACTION_TYPES).includes(el.t.toString())
        })
        for (const block of blocksWithGamification) {
            if (block.isEnableRating) {
                if (block.numberOfAttempts) {
                    await API__PROJECTS.SEND_CONSTRAINT(GAMIFICATION_ACTION_TYPES[block.t].finish, id, block.id, {
                        value: block.numberOfAttempts,
                    })
                } else {
                    await API__PROJECTS.REMOVE_CONSTRAINT(GAMIFICATION_ACTION_TYPES[block.t].finish, id, block.id)
                }
            }
        }
    }

    /**
     * Change temporary project
     * @param {string} target
     * @param {any} value
     */
    changeProject = async (target, value) => {
        const { tmpProject } = this.state
        const _tmpProject = cloneDeep(tmpProject)
        set(_tmpProject, target, value)
        await this.setState({ tmpProject: _tmpProject })
    }

    showConfirmationDialog = (type, payload) => {
        this.setState({
            confirmationDialog: {
                isOpen: true,
                type,
                payload,
            },
        })
    }
    closeConfirmationDialog = () => {
        this.setState({
            confirmationDialog: {
                isOpen: false,
                type: undefined,
                payload: {},
            },
        })
    }

    render() {
        const { history, i18n, isReady } = this.props
        const {
            tmpProject,
            processes,
            confirmationDialog,
            isOpenGamificationPromo,
            isShowSelectOrganization,
            isShowMultiplayerWelcome,
            isMultiplayerGame,
        } = this.state

        return (
            <div
                className={classNames(styles.editorNew, 'editor-new')}
                style={{ pointerEvents: processes.publishing ? 'none' : 'auto' }}
            >
                <Helmet>
                    <title>{i18n.t('Editor')} | Interacty</title>
                </Helmet>
                {isReady && (
                    <>
                        <ProjectContext.Provider value={{ project: tmpProject, isMultiplayerGame }}>
                            <TopBar
                                data={{
                                    processes,
                                    project: tmpProject,
                                }}
                                history={history}
                                methods={{
                                    save: this.updateProject,
                                    changeProject: this.changeProject,
                                    publishProject: this.publishProject,
                                }}
                            />
                            <Switch>
                                <Route
                                    exact
                                    path="/editor/:projectId"
                                    render={() => (
                                        <EditorTab
                                            isMultiplayerGame={isMultiplayerGame}
                                            projectId={tmpProject.id}
                                            projectStructureJson={tmpProject.projectStructureJson}
                                            onChangeProject={this.changeProject}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/editor/:projectId/preview"
                                    render={() => <PreviewTab project={tmpProject} />}
                                />
                                <Route
                                    exact
                                    path="/editor/:projectId/integrations"
                                    render={() => (
                                        <IntegrationsTab
                                            data={{
                                                projectId: tmpProject.id,
                                                projectUuid: tmpProject.projectId,
                                                integrations: tmpProject.projectStructureJson.integrations,
                                            }}
                                            history={history}
                                            methods={{
                                                changeProject: this.changeProject,
                                            }}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/editor/:projectId/sharing-options"
                                    render={() => (
                                        <SharingOptionsTab
                                            project={tmpProject}
                                            history={history}
                                            methods={{
                                                save: this.updateProject,
                                                changeProject: this.changeProject,
                                            }}
                                        />
                                    )}
                                />

                                <Redirect from="*" to="/" />
                            </Switch>
                        </ProjectContext.Provider>

                        {confirmationDialog.isOpen &&
                            confirmationDialog.type === CONFIRMATION_DIALOG_TYPES.emptyHomePage && (
                                <ConfirmationDialog
                                    onClose={() => this.closeConfirmationDialog()}
                                    onAction={() => this.publishProject(true) && this.closeConfirmationDialog()}
                                    data={{
                                        headText: i18n.t("You haven't created any block in home page"),
                                        noteText: i18n.t('You can still publish the project, but it will be empty'),
                                        actionVariant: 'primary',
                                        actionText: i18n.t('Publish'),
                                        cancelText: i18n.t('Cancel'),
                                    }}
                                />
                            )}
                        {confirmationDialog.isOpen &&
                            confirmationDialog.type === CONFIRMATION_DIALOG_TYPES.emptyProject && (
                                <ConfirmationDialog
                                    onClose={() => this.closeConfirmationDialog()}
                                    onAction={() => this.publishProject(true) && this.closeConfirmationDialog()}
                                    data={{
                                        headText: i18n.t("You haven't created any block"),
                                        noteText: i18n.t('You can still publish the project, but it will be empty'),
                                        actionVariant: 'primary',
                                        actionText: i18n.t('Publish'),
                                        cancelText: i18n.t('Cancel'),
                                    }}
                                />
                            )}
                        {confirmationDialog.isOpen &&
                            confirmationDialog.type === CONFIRMATION_DIALOG_TYPES.premiumBlocks && (
                                <ConfirmationDialog
                                    onClose={() => this.closeConfirmationDialog()}
                                    onAction={async () => {
                                        await this.autoSave()
                                        history.push('/billing-and-payments?tab=subscription')
                                    }}
                                    data={{
                                        headText: i18n.t('Publication not available!'),
                                        noteText: (
                                            <>
                                                <p>
                                                    {i18n.t('Unable to publish the following blocks: ')}
                                                    {confirmationDialog.payload.blocks.map((block, ind) => (
                                                        <span key={block.t} style={{ fontWeight: 'bold' }}>
                                                            {block.label}
                                                            {ind < confirmationDialog.payload.blocks.length - 1
                                                                ? ', '
                                                                : '.'}
                                                        </span>
                                                    ))}
                                                </p>
                                                <p>{i18n.t('Your plan needs to be upgraded.')}</p>
                                            </>
                                        ),
                                        actionVariant: 'primary',
                                        actionText: i18n.t('Upgrade plan'),
                                        cancelText: i18n.t('Close'),
                                        image: girlSitting,
                                    }}
                                />
                            )}

                        <GamificationPromo
                            isOpen={isOpenGamificationPromo}
                            onUpSubscription={async () => {
                                await this.autoSave()
                                history.push('/billing-and-payments?tab=subscription')
                            }}
                            onPublish={() =>
                                this.publishProject(true) && this.setState({ isOpenGamificationPromo: false })
                            }
                        />

                        {isShowMultiplayerWelcome && (
                            <MultiplayerWelcome onClose={() => this.setState({ isShowMultiplayerWelcome: false })} />
                        )}
                    </>
                )}

                {isShowSelectOrganization && (
                    <SelectOrganization
                        onCancel={() => (window.location.href = getFullUrlToSsr('/template-gallery'))}
                        onSelect={async orgId => {
                            await this.createProject(orgId)
                            this.setState({
                                isShowSelectOrganization: false,
                            })
                        }}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    organizations: state.organizations,
    user_details: state.user_details,
})
const mapDispatchToProps = dispatch => {
    return {
        SET_ORGANIZATION_AS_SELECTED: data => dispatch(ORGANIZATIONS__SET_ORGANIZATION_AS_SELECTED(data)),
        SET_PROJECT_SUBSCRIPTION: data => dispatch(PROJECTS__SET_PROJECT_SUBSCRIPTION(data)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('translations'),
    withInitialLoading,
)(Editor)
