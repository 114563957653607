import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Mixpanel from 'common/services/Mixpanel'
import { isEducation } from 'common/utils/permissions'

import EMAILS from 'common/constants/emails'

import womanNotebook from '../../../i/womanNotebook.svg'

import './Content.scss'

const COUNT_NEVER_TRIAL_USES = 0

const ForOwnerOfOrgExpirationContent = ({
    trialUsesCount,
    isTrial,
    isFreeSubscription,
    isDeprecatedSubscription,
    onExtend,
    onUseEducationFree,
}) => {
    const { t } = useTranslation()
    const neverUseTrial = trialUsesCount === COUNT_NEVER_TRIAL_USES

    useEffect(() => {
        if (isTrial) Mixpanel.track('Trial Expiration Dialog Showed', { trialUsesCount })
    }, [])

    return (
        <div className="subscription-expired-modal__content">
            <img src={womanNotebook} alt="" />
            {isTrial || isDeprecatedSubscription ? (
                <>
                    <div className="title">
                        {neverUseTrial ? t('Free trial') : t('Your free trial period has ended')}
                    </div>

                    {isDeprecatedSubscription && (
                        <div className="text">
                            <p>
                                {t(
                                    "In order to further improve the quality of service, we have decided to abandon the free plan. Don't worry, all of your previously published projects will work and remain available to users. In addition, you can extend the free period.",
                                )}
                            </p>
                        </div>
                    )}
                    {neverUseTrial ? (
                        <div className="text">
                            <p>
                                <b>{t('Choose a plan or extend your trial period')}</b>
                            </p>
                        </div>
                    ) : (
                        <div className="text">
                            <p>
                                <b>{t('Choose the tariff that best suits your needs')}</b>
                            </p>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="title">{t('Oh, your subscription has expired')}</div>
                    <div className="text">
                        <p>
                            <b>{t('Choose the tariff that best suits your needs')}</b>
                        </p>
                    </div>
                </>
            )}

            <div className="button-container">
                <Link className="modal-button modal-button--primary" to="/billing-and-payments?tab=subscription">
                    {t('Choose a plan')}
                </Link>
                {isEducation() ? (
                    <>
                        <br />
                        <button
                            className="modal-button modal-button--secondary"
                            data-test="extend-trial"
                            onClick={() => onUseEducationFree()}
                        >
                            {t('Use free tariff plan')}
                        </button>
                    </>
                ) : neverUseTrial && isFreeSubscription ? (
                    <>
                        <br />
                        <button
                            className="modal-button modal-button--secondary"
                            data-test="extend-trial"
                            onClick={() => onExtend()}
                        >
                            {t('Continue my free trial')}
                        </button>
                    </>
                ) : (
                    <div className="trial-extend-query">
                        <p>{t("Couldn't find a suitable tariff or need help?")}</p>
                        <a href={`mailto:${EMAILS.INTERACTY_INFO}`}>{t('Contact us')}</a>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ForOwnerOfOrgExpirationContent
