import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'

import { createCollector, createValidator, isPasswordRules, isEmailRules } from 'shared/validation'

import { getFullUrlToSsr } from 'utils/router'
import { API__AUTH } from 'api'

import Mixpanel from 'common/services/Mixpanel'

import Toast from 'components/Toast/Toast'

import { sendGoogleAnalyticsEvent } from 'hooks/useGoogleAnalytics'

import Body from './components/Body/Body'
import Button from './components/Button/Button'
import TextControl from './components/Inputs/components/Controls/Text/Text'
import Inputs from './components/Inputs/Inputs'
import Notice from './components/Notice/Notice'
import NotSeeEmail from './components/NotSeeEmail/NotSeeEmail'

import plumageImage from './i/plumage.svg'

const STEPS = {
    STEP_1: 'STEP_1',
    STEP_2: 'STEP_2',
}

const Step1 = ({ onSuccess }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            const _email = email.trim()
            const fields = {
                email: _email,
                password,
            }
            const validationRules = {
                email: isEmailRules,
                password: isPasswordRules,
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            await API__AUTH.REGISTRATION({
                email: _email,
                password,
                newsSubscription: true,
            })
            sendGoogleAnalyticsEvent('registration')
            Mixpanel.track('User registered', { email: _email, provider: 'interacty' })
            onSuccess(_email)
        } catch (err) {
            console.error(err)
            if (err.response) {
                const {
                    response: { status, data },
                } = err

                if (status === 400) {
                    if (data.kind.code === 5) {
                        if (data.message === 'Password is not valid') {
                            setErrors(prevState => ({
                                ...prevState,
                                password: [t('Password is not valid')],
                            }))
                            return
                        }
                        if (data.message === 'Email is not valid') {
                            setErrors(prevState => ({
                                ...prevState,
                                email: [t('Email is not valid')],
                            }))
                            return
                        }
                        if (data.message === 'User with supplied credentials already exists') {
                            setErrors(prevState => ({
                                ...prevState,
                                email: [t('User with this email already exist')],
                            }))
                            return
                        }
                        if (data.message === 'Bad request') {
                            if (data.exception_data?.login === 'Email is not valid') {
                                setErrors(prevState => ({
                                    ...prevState,
                                    email: [t('Email is not valid')],
                                }))
                                return
                            }
                        }
                    }
                }
            }
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Body
            title={t('Get started absolutely free.')}
            description={t('No credit card needed')}
            onSubmit={() => onSubmit()}
            isForm={true}
        >
            <Inputs>
                <TextControl
                    label={t('Email address')}
                    value={email}
                    data-test="signup-username"
                    onUpdate={v => {
                        setEmail(v)
                        setErrors({ ...errors, email: [] })
                    }}
                    errorMessages={errors.email || []}
                />
                <TextControl
                    label={t('Password')}
                    value={password}
                    data-test="signup-password"
                    isSecure={true}
                    onUpdate={v => {
                        setPassword(v)
                        setErrors({ ...errors, password: [] })
                    }}
                    errorMessages={errors.password || []}
                />
            </Inputs>
            <Button>
                <button
                    data-test="signup-submit"
                    className="button"
                    data-size="lg"
                    data-variant="primary"
                    disabled={isLoading}
                >
                    {t('Create account')}
                </button>
            </Button>
            <Notice>
                <Trans
                    i18nKey="By creating account, I agree to <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Policy</privacyLink>"
                    components={{
                        termsLink: (
                            <a href={`${getFullUrlToSsr()}/terms-of-use`} target="_blank" rel="noopener noreferrer" />
                        ),
                        privacyLink: (
                            <a href={`${getFullUrlToSsr()}/privacy-policy`} target="_blank" rel="noopener noreferrer" />
                        ),
                    }}
                />
            </Notice>
        </Body>
    )
}
const Step2 = ({ email }) => {
    const { t } = useTranslation()

    return (
        <Body
            image={plumageImage}
            title={t('Almost done!')}
            description={
                <Trans
                    i18nKey="We just emailed a confirmation link to <bold>{{email}}</bold><br/>Please check your inbox and activate your profile."
                    values={{ email }}
                    components={{
                        bold: <span />,
                    }}
                />
            }
            isForm={false}
        >
            <NotSeeEmail email={email} />
        </Body>
    )
}

const Registration = () => {
    const { t } = useTranslation()

    const [step, setStep] = useState(STEPS.STEP_1)
    const [userEmail, setUserEmail] = useState('')

    return (
        <>
            <Helmet>
                <title>{t('Registration')} | Interacty</title>
            </Helmet>
            {step === STEPS.STEP_1 && (
                <Step1
                    onSuccess={email => {
                        setUserEmail(email)
                        setStep(STEPS.STEP_2)
                    }}
                />
            )}
            {step === STEPS.STEP_2 && <Step2 email={userEmail} />}
        </>
    )
}

export default Registration
