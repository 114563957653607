import React from 'react'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { animateScroll as scroll } from 'react-scroll'
import { connect } from 'react-redux'
import { compose } from 'redux'
import qs from 'qs'

import { getProjectEmbedCodeWithIFrame, getProjectEmbedCodeWithLoader } from 'utils/embed' // for cd
import {
    isSubscriptionExpired,
    isDeprecatedSubscription,
    isTrialSubscription,
    isEducation,
    isSubscriptionActivated,
    isProjectLimitExceeded,
    isHasActiveProjectSubscription,
} from 'common/utils/permissions'
import Mixpanel from 'common/services/Mixpanel'
import { isBlockArticleType } from 'common/utils/isBlockArticleType'
import { buildProjectUrl } from 'common/utils/project'
import { STORE } from 'common/constants/payments'

import Toast from 'components/Toast/Toast'
import SelectOrganization from 'components/Modal/SelectOrganization/SelectOrganization'
import { MODALS } from 'components/ModalManager/constants'

import {
    ORGANIZATIONS__SET_ORGANIZATION_AS_SELECTED,
    ORGANIZATIONS__SET_SELECTED_FOLDER_ID,
    ORGANIZATIONS__SET_SELECTED_MEMBER,
    ORGANIZATIONS_REFRESH_MEMBERS,
    MODAL_MANAGER_SET_MODAL,
} from 'store/actions'
import { API__FOLDERS, API__PROJECTS, HTTP_STATUS, API__ORGANIZATIONS } from 'api'

import FolderList from './components/FolderList/FolderList'

import { ProjectList } from './components/ProjectList/ProjectList'
import { ALL_PROJECT_FILTER_ITEM } from './components/ProjectStatusesFilter/ProjectStatusesFilter'
import TrialProgressBanner from './components/TrialProgressBanner'
import OrganizationPanel from './components/OrganizationPanel/OrganizationPanel'
import QuestionBankPanel from './components/QuestionBankPanel/QuestionBankPanel'
import OrganizationLimits from './components/OrganizationLimits/OrganizationLimits'
import TopBar from './components/TopBar/TopBar'

import ModalController, { MODAL_TYPES } from './modals/ModalController'

import { ALL_PROJECTS_MEMBER, REDIRECT_ACTIONS } from './utils/constants'

import './MyProjects.scss'

class MyProjects extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            openedModals: [],
            isLoadingProjects: true,
            cloningProject: null,
            isShowSelectOrganizationForClone: false,
            projects: {
                page: 0,
                totalPages: 0,
                status: null,
                content: [],
            },
            isLoadingFolders: true,
            folders: {
                content: [],
                path: null,
            },
        }
    }

    async componentDidMount() {
        const {
            location,
            organizations,
            SET_ORGANIZATION_AS_SELECTED,
            modalManagerSetModal,
            user_details,
        } = this.props

        const { order_guid, store } = qs.parse(location.search, { ignoreQueryPrefix: true })

        this.openDecisionOrgInviteModals(organizations.organizationList)

        const isHasOnlyOwnOrg = organizations.organizationList.length === 1
        if (location.state) {
            const { action, data } = location.state
            if (data?.organizationId) {
                await SET_ORGANIZATION_AS_SELECTED(data.organizationId)
            }
            if (action && action === REDIRECT_ACTIONS.editorPublished) {
                await this.openPublishedModal(location)
            }
            if (action && action === REDIRECT_ACTIONS.editorMultiplayerPublished) {
                await this.onOpenStartGameModal(data.project)
            }
            if (action && action === REDIRECT_ACTIONS.reachedQuotaPremiumTemplates) {
                await this.openUpgradeModal(location)
            }
            if (action && action === REDIRECT_ACTIONS.selectedOrganizationSubscriptionExpired) {
                const selectOrg = organizations.organizationList.find(org => org.id === data?.organizationId)
                const isOwner = selectOrg?.ownerId === user_details.id
                await this.onOpenModal(MODAL_TYPES.subscriptionExpired, { isOwner })
            }
            if (action && action === REDIRECT_ACTIONS.projectLimitExceeded) {
                modalManagerSetModal(MODALS.PROJECTS_LIMITS_MODAL)
            }
        } else if (isDeprecatedSubscription() && isEducation()) {
            await this.onOpenModal(MODAL_TYPES.educationModal)
        } else if (
            !isHasActiveProjectSubscription() &&
            (isDeprecatedSubscription() || (isSubscriptionExpired() && isHasOnlyOwnOrg && isSubscriptionActivated()))
        ) {
            await this.onOpenModal(MODAL_TYPES.subscriptionExpired)
        } else if (order_guid && store !== STORE.YOOMONEY) {
            await this.onOpenModal(MODAL_TYPES.upgradeSuccessful)
        }

        this.loadFolders()
    }

    componentDidUpdate(prevProps) {
        const organizations = this.props.organizations
        const isMemberChanged = organizations.selectedMember !== prevProps.organizations.selectedMember
        const isOrgChanged = organizations.selectedOrganizationId !== prevProps.organizations.selectedOrganizationId
        const isFolderChanged = organizations.selectedFolderId !== prevProps.organizations.selectedFolderId
        if (isOrgChanged || isMemberChanged || isFolderChanged) {
            this.loadProjects()
        }
        if (isOrgChanged || isFolderChanged) {
            this.loadFolders()
        }
    }

    openDecisionOrgInviteModals = organizationList => {
        organizationList.forEach(async org => {
            if (!org.isApproved) {
                await this.onOpenModal(MODAL_TYPES.decisionOrganizationInviteModal, org)
            }
        })
    }

    loadFolders = async (isInitLoading = true) => {
        const {
            organizations: { selectedOrganizationId, selectedFolderId },
        } = this.props
        if (isInitLoading) this.setState({ isLoadingFolders: true })
        try {
            const response = await API__FOLDERS.GET_FOLDERS(selectedOrganizationId, selectedFolderId)
            await this.setState(prevState => ({
                folders: {
                    ...prevState.folders,
                    content: response.folders.content,
                    path: response.path,
                    pagination: {
                        ...prevState.folders.pagination,
                        totalPages: response.totalPages,
                    },
                },
            }))
        } finally {
            await this.setState({ isLoadingFolders: false })
        }
    }

    loadProjects = async (isResetPagination = true) => {
        const {
            organizations: { selectedOrganizationId, selectedMember, selectedFolderId },
        } = this.props

        await this.setState({ isLoadingProjects: true })
        try {
            const {
                projects: { page, status },
            } = this.state
            const params = {}
            // Pagination
            params.page = isResetPagination ? 0 : page
            // Filter
            if (status !== ALL_PROJECT_FILTER_ITEM) params.status = status
            params.authorId = selectedMember ? selectedMember.userId : null
            if (!params.authorId) params.folderId = selectedFolderId
            params.organizationId = selectedOrganizationId

            const projects = await API__PROJECTS.GET_PROJECTS(params)
            await this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    content: projects.content,
                    page: projects.number,
                    totalPages: projects.totalPages,
                },
            }))
        } finally {
            await this.setState({ isLoadingProjects: false })
        }
    }

    refreshMembers = () => {
        const { REFRESH_MEMBERS } = this.props
        REFRESH_MEMBERS()
    }

    openPublishedModal = async location => {
        const { history } = this.props
        const { data } = location.state
        const payload = {
            project: data.project,
            projectUrl: data.projectUrl,
            embedCode: data.embedCode,
            iframeCode: data.iframeCode,
        }
        await this.onOpenModal(MODAL_TYPES.publishModal, payload)

        history.replace({ search: location.search })
    }

    openUpgradeModal = async location => {
        const { t, history } = this.props
        await this.onOpenModal(MODAL_TYPES.upgradeDialog, {
            text: t('Upgrade your plan to use premium templates'),
        })
        history.replace({ search: location.search })
    }

    onChangeFilter = async (status, evt) => {
        if (evt) evt.preventDefault()
        await this.setState(prevState => ({
            projects: {
                ...prevState.projects,
                status,
            },
        }))
        await this.loadProjects()
    }

    onScrollToTop = () => {
        scroll.scrollToTop({
            duration: 400,
        })
    }

    onEditProject = async (project, evt) => {
        const { history } = this.props

        if (evt) evt.preventDefault()

        if (isSubscriptionExpired(project.projectSubscription)) {
            await this.onOpenModal(MODAL_TYPES.subscriptionExpired)
            return
        }

        if (isBlockArticleType(project.projectType.projectType)) {
            Mixpanel.track('Project edited', { projectId: project.id })
            history.push(`/editor/${project.projectId}`)
        } else {
            history.push(`/editor?projectId=${project.projectId}&id=${project.id}`)
        }
    }
    onRenameProject = async (project, name) => {
        const { t } = this.props

        await API__PROJECTS.UPDATE_PROJECT(project.projectId, {
            id: project.id,
            projectId: project.projectId,
            name: name.trim(),
        })
            .then(() => {
                this.setState(prevState => ({
                    projects: {
                        ...prevState.projects,
                        content: prevState.projects.content.map(item =>
                            item.projectId === project.projectId ? { ...item, name } : item,
                        ),
                    },
                }))
            })
            .catch(error => {
                Toast('error', {
                    message: t(error.response.data.message),
                })
                throw error
            })
    }
    onDeleteProject = async ({ projectId }, evt) => {
        const { t } = this.props

        if (evt) {
            evt.preventDefault()
        }

        try {
            await API__PROJECTS.DELETE_PROJECT(projectId)
            this.refreshMembers()
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    content: prevState.projects.content.map(item =>
                        item.projectId === projectId ? { ...item, isRemoved: true } : item,
                    ),
                },
            }))
            Toast('success', { message: t('Project was deleted successfully!') })
        } catch (error) {
            Toast('error', { message: t('Sorry, looks like there was some error. Please, try again later.') })
            throw error
        } finally {
            await this.onCloseModal(MODAL_TYPES.deleteProjectModal)
        }
    }
    onDuplicateProject = async (data, evt) => {
        if (evt) evt.preventDefault()
        if (this.state.cloningProject) return

        this.setState({ cloningProject: data, isShowSelectOrganizationForClone: true })
    }

    onDeleteTemplate = async ({ projectId }, evt) => {
        const { t } = this.props

        if (evt) evt.preventDefault()

        try {
            await API__PROJECTS.DELETE_PROJECT(projectId)
            this.refreshMembers()
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    content: prevState.projects.content.map(item =>
                        item.projectId === projectId ? { ...item, isRemoved: true } : item,
                    ),
                },
            }))
            Toast('success', { message: t('Template was deleted successfully!') })
        } catch (error) {
            Toast('error', {})
            throw error
        } finally {
            await this.onCloseModal(MODAL_TYPES.deleteTemplateModal)
        }
    }

    cloneProject = async (data, organizationId) => {
        try {
            const {
                organizations: { selectedSubscription },
            } = this.props
            const isChosenOrganizationSameSelected = selectedSubscription.id === organizationId

            const chosenOrganizationSubscription = isChosenOrganizationSameSelected
                ? selectedSubscription
                : await API__ORGANIZATIONS.GET_ORGANIZATION_SUBSCRIPTION(organizationId)

            if (isSubscriptionExpired(chosenOrganizationSubscription)) {
                await this.onOpenModal(MODAL_TYPES.upgradeDialog)
                return
            }

            if (isSubscriptionExpired(data.projectSubscription)) {
                await this.onOpenModal(MODAL_TYPES.subscriptionExpired)
                return
            } else if (isProjectLimitExceeded(chosenOrganizationSubscription)) {
                this.props.modalManagerSetModal(MODALS.PROJECTS_LIMITS_MODAL)
                return
            }

            const {
                t,
                organizations: { selectedFolderId },
                SET_SELECTED_MEMBER,
            } = this.props

            await API__PROJECTS.CLONE_PROJECT(
                data.id,
                isChosenOrganizationSameSelected ? selectedFolderId : null,
                organizationId,
            )

            if (isChosenOrganizationSameSelected) {
                this.refreshMembers()
                if (!selectedFolderId) SET_SELECTED_MEMBER(ALL_PROJECTS_MEMBER)
            }

            this.onScrollToTop()
            await this.loadProjects()
            Toast('success', {
                message: t('Project successfully duplicated'),
            })
        } catch (err) {
            console.error(err)
            const {
                response: {
                    status,
                    data: { message },
                },
            } = err
            if (status === HTTP_STATUS.Forbidden || status === HTTP_STATUS.PaymentRequired) {
                Toast('error', { message })
            }
        } finally {
            await this.setState({
                cloningProject: null,
            })
        }
    }

    onChangeProjectVisibility = async (project, isHidden) => {
        try {
            await API__PROJECTS.CHANGE_VISIBILITY({
                projectId: project.id,
                isHidden,
            })

            const { t } = this.props
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    content: prevState.projects.content.map(item =>
                        item.projectId === project.projectId ? { ...item, hidden: isHidden } : item,
                    ),
                },
            }))
            Toast('success', {
                message: t('Project settings successfully updated'),
            })
        } catch (err) {
            Toast('error')
            console.error(err)
        }
    }

    onOpenModal = async (type, payload, evt) => {
        if (evt) evt.preventDefault()

        await this.setState(prevState => ({
            openedModals: [...prevState.openedModals, { type, payload }],
        }))
    }

    onCloseModal = async (type, evt) => {
        if (evt) evt.preventDefault()
        await this.setState(prevState => ({
            openedModals: prevState.openedModals.filter(modal => modal.type !== type),
        }))
    }

    onOpenStartGameModal = async (project, evt) => {
        if (evt) evt.preventDefault()
        await this.onOpenModal(MODAL_TYPES.manageMultiplayerGameModal, { project })
    }

    onOpenShareModal = async (project, evt) => {
        if (evt) evt.preventDefault()

        if (isSubscriptionExpired(project.projectSubscription)) {
            await this.onOpenModal(MODAL_TYPES.subscriptionExpired)
            return
        }

        const hashOfProject = project.projectVersions[0].versionHash
        const embedCode = getProjectEmbedCodeWithLoader(hashOfProject)
        const iframeCode = getProjectEmbedCodeWithIFrame(hashOfProject)

        const payload = {
            project,
            projectUrl: buildProjectUrl(project.customUrl, hashOfProject),
            embedCode,
            iframeCode,
        }
        await this.onOpenModal(MODAL_TYPES.shareModal, payload)
    }

    onPaginationClick = () => async data => {
        this.onScrollToTop()
        await this.setState(prevState => ({
            projects: {
                ...prevState.projects,
                page: data.selected,
            },
        }))
        await this.loadProjects(false)
    }

    onCreateProject = async (templateId, isMultiplayerGame = false) => {
        const {
            organizations: { selectedOrganizationId },
            modalManagerSetModal,
        } = this.props

        if (isSubscriptionExpired()) {
            await this.onOpenModal(MODAL_TYPES.subscriptionExpired)
            return
        } else if (isProjectLimitExceeded()) {
            modalManagerSetModal(MODALS.PROJECTS_LIMITS_MODAL)
            return
        }

        let _templateId
        if (typeof templateId === 'number') {
            _templateId = templateId
        } else {
            _templateId = isMultiplayerGame
                ? process.env.REACT_APP_MULTIPLAYER_TEMPLATE_ID
                : process.env.REACT_APP_SCRATCH_TEMPLATE_ID
        }

        this.props.history.push(`/editor?templateId=${_templateId}&organizationId=${selectedOrganizationId}`)
    }

    handleOpenCreateFolderModal = async () => {
        await this.onOpenModal(MODAL_TYPES.createFolder, { onAddedFolder: () => this.loadFolders(false) })
    }

    handleMoveFolderModal = async folderId => {
        await this.onOpenModal(MODAL_TYPES.moveFolder, {
            movingFolderId: folderId,
            folders: this.state.folders.content,
            path: this.state.folders.path,
            onUpdateList: () => this.filterFolderState(folderId),
        })
    }

    handleOpenFolder = async folderId => {
        const { SET_SELECTED_FOLDER_ID } = this.props
        this.setState(prevState => ({
            projects: {
                ...prevState.projects,
                status: ALL_PROJECT_FILTER_ITEM,
            },
            folders: {
                ...prevState.folders,
                currentFolderId: folderId,
            },
        }))
        await SET_SELECTED_FOLDER_ID(folderId)
    }
    handleRenameFolder = async (id, name, parentId) => {
        const { t } = this.props
        API__FOLDERS.UPDATE_FOLDER({ id, name, parentId })
            .then(() => {
                Toast('success', { message: t('Folder renamed') })
            })
            .catch(() => {
                Toast('error')
            })
    }
    handleRemoveFolder = async folderId => {
        const {
            organizations: { selectedOrganizationId },
        } = this.props
        API__FOLDERS.DELETE_FOLDER(folderId, selectedOrganizationId).then(() => this.filterFolderState(folderId))
    }

    filterFolderState(folderId) {
        this.setState(prevState => ({
            folders: {
                ...prevState.folders,
                content: prevState.folders.content.filter(item => item.id !== folderId),
            },
        }))
    }

    handleMoveProjectModal = async projectId => {
        await this.onOpenModal(MODAL_TYPES.moveProjectToFolder, {
            movingProjectId: projectId,
            folders: this.state.folders.content,
            onUpdateList: movedToFolderId => {
                this.setState(prevState => ({
                    folders: {
                        ...prevState.folders,
                        content: prevState.folders.content.map(item =>
                            item.id === movedToFolderId ? { ...item, projectsCount: item.projectsCount + 1 } : item,
                        ),
                    },
                }))
                this.loadProjects(false)
            },
        })
    }

    render() {
        const {
            t,
            organizations: { selectedSubscription, selectedMember },
        } = this.props
        const { isShowSelectOrganizationForClone } = this.state

        const { isLoadingProjects, cloningProject, projects, openedModals, isLoadingFolders, folders } = this.state

        const isTrial = isTrialSubscription()

        return (
            <div className="page-projects">
                <Helmet>
                    <title>{t('My projects')} | Interacty</title>
                </Helmet>

                <div className="page-projects__panels">
                    <div className="page-projects__panels__content">
                        <OrganizationPanel
                            selectedMember={selectedMember}
                            onOpenModal={this.onOpenModal}
                            onScrollToTop={this.onScrollToTop}
                        />
                        <OrganizationLimits />
                        <QuestionBankPanel />
                    </div>
                </div>

                <section className="page-projects__projects-block">
                    {selectedSubscription.started && (
                        <TrialProgressBanner
                            isVisible={isTrial}
                            startDate={selectedSubscription.started}
                            expirationDate={selectedSubscription.expirationDate}
                        />
                    )}
                    <TopBar
                        folderPaths={folders.path}
                        selectedMember={selectedMember}
                        onGoToFolder={this.handleOpenFolder}
                        onCreateFromScratch={isMultiplayerGame => this.onCreateProject(null, isMultiplayerGame)}
                    />
                    {!selectedMember.id && (
                        <FolderList
                            isLoading={isLoadingFolders}
                            folders={folders.content}
                            path={folders.path}
                            onCreateFolder={this.handleOpenCreateFolderModal}
                            onOpenFolder={this.handleOpenFolder}
                            onRenameFolder={this.handleRenameFolder}
                            onMoveFolder={this.handleMoveFolderModal}
                            onDeleteFolder={this.handleRemoveFolder}
                        />
                    )}
                    <ProjectList
                        isLoading={isLoadingProjects}
                        projects={projects}
                        isProjectCloning={!!cloningProject}
                        onOpenModal={this.onOpenModal}
                        onEditProject={this.onEditProject}
                        onOpenStartGameModal={this.onOpenStartGameModal}
                        onOpenShareModal={this.onOpenShareModal}
                        onDuplicateProject={this.onDuplicateProject}
                        onChangeProjectVisibility={this.onChangeProjectVisibility}
                        onPaginationClick={this.onPaginationClick}
                        onRenameProject={this.onRenameProject}
                        onCreateProject={this.onCreateProject}
                        onChangeFilter={this.onChangeFilter}
                        onMoveProject={this.handleMoveProjectModal}
                    />
                </section>
                {isShowSelectOrganizationForClone && (
                    <SelectOrganization
                        title={t('Clone project')}
                        onCancel={() => {
                            this.setState({
                                cloningProject: null,
                                isShowSelectOrganizationForClone: false,
                            })
                        }}
                        onSelect={async organizationId => {
                            await this.cloneProject(cloningProject, organizationId)
                            this.setState({
                                cloningProject: null,
                                isShowSelectOrganizationForClone: false,
                            })
                        }}
                    />
                )}
                <ModalController
                    onOpenModal={this.onOpenModal}
                    openedModals={openedModals}
                    onCloseModal={this.onCloseModal}
                    onLoadProjects={this.loadProjects}
                    onDeleteProject={this.onDeleteProject}
                    onDeleteTemplate={this.onDeleteTemplate}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user_details: state.user_details,
    user_subscriptions: state.user_subscriptions,
    organizations: state.organizations,
})

const mapDispatchToProps = dispatch => {
    return {
        REFRESH_MEMBERS: () => dispatch(ORGANIZATIONS_REFRESH_MEMBERS()),
        modalManagerSetModal: (name, payload) => dispatch(MODAL_MANAGER_SET_MODAL(name, payload)),
        SET_SELECTED_MEMBER: member => dispatch(ORGANIZATIONS__SET_SELECTED_MEMBER(member)),
        SET_ORGANIZATION_AS_SELECTED: data => dispatch(ORGANIZATIONS__SET_ORGANIZATION_AS_SELECTED(data)),
        SET_SELECTED_FOLDER_ID: data => dispatch(ORGANIZATIONS__SET_SELECTED_FOLDER_ID(data)),
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('translations'))(MyProjects)
