import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

import { getCookie } from 'utils/cookies'
import {COOKIE_PREFERENCES_KEYS, isHasCookiePreference} from "../common/constants/cookies";

const GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

let isInitialized = false

const isEnabled = () => !!GA_ID && isHasCookiePreference(COOKIE_PREFERENCES_KEYS.ANALYTICAL)

export const sendGoogleAnalyticsEvent = (category, action = 'send', label = 'common', value = 0) => {
    if (!isInitialized) return
    ReactGA.event({
        category,
        action,
        label,
        value,
    })
}

export const GoogleAnalyticsTracker = () => {
    const location = useLocation()
    const userDetails = useSelector(state => state.user_details)

    useEffect(() => {
        if (!isInitialized) return
        const { pathname, search } = location
        const page = pathname + search
        const obj = {
            page,
            location: `${window.location.origin}${page}`,
        }
        if (userDetails?.cid) obj.dimension1 = userDetails.cid
        else {
            const ga = getCookie('_ga')
            if (typeof ga !== 'undefined') obj.dimension1 = ga
        }

        ReactGA.set(obj)
    }, [userDetails])

    useEffect(() => {
        if (!isInitialized) return
        const { pathname, search } = location
        ReactGA.pageview(pathname + search)
    }, [location])

    return null
}

const useGoogleAnalytics = () => {
    useEffect(() => {
        if (!document || !isEnabled()) return
        console.log('"Google analytics" initialization')
        ReactGA.initialize(GA_ID)
        isInitialized = true
    }, [])
}

export default useGoogleAnalytics
