import React, { useState, useEffect } from 'react'

import i18n from 'i18n'

import { API__COMMON } from 'api'

import { EDUCATION_GROUP_ID } from 'common/constants/ProductConstants'
import Mixpanel from 'common/services/Mixpanel'
import { isAppSumoUser } from 'common/utils/permissions'
import { isEuVersion } from 'common/constants/env'

import Toast from 'components/Toast/Toast'

import Description from '../../components/Description/Description'

import Groups from './components/Groups/Groups'
import Roles from './components/Roles/Roles'

import { STEPS_ENUM, TITLES, SUB_TITLES } from './constants'

import styles from './ChooseRole.module.scss'

const NewUserModal = ({ onSelect = () => {} }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [step, setStep] = useState(STEPS_ENUM.SELECT_GROUP)
    const [list, setList] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        API__COMMON.GET_USERS_PROJECTS_ROLES()
            .then(usersRoles => {
                if (isEuVersion) {
                    const educationGroup = usersRoles.find(group => group.customId === EDUCATION_GROUP_ID)
                    if (educationGroup) {
                        setSelectedGroup(educationGroup)
                        setStep(STEPS_ENUM.SELECT_ROLE)
                        return
                    }
                }
                setList(usersRoles)
            })
            .catch(err => {
                console.error(err)
                Toast('error', {})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const getActionButtonText = group => {
        if (isAppSumoUser() || group.customId === EDUCATION_GROUP_ID) return i18n.t('Next')
        return i18n.t('Start my free trial')
    }

    return (
        <div className={styles.chooseRole}>
            <Description title={TITLES[step]} subTitle={SUB_TITLES[step]} />
            {step === STEPS_ENUM.SELECT_GROUP && (
                <Groups
                    list={list}
                    isLoading={isLoading}
                    onSelect={group => {
                        setSelectedGroup(group)
                        setStep(STEPS_ENUM.SELECT_ROLE)
                        Mixpanel.track('User group chosen', { groupId: group.customId })
                    }}
                />
            )}
            {step === STEPS_ENUM.SELECT_ROLE && (
                <Roles
                    backButtonText={i18n.t('Back')}
                    actionButtonText={getActionButtonText(selectedGroup)}
                    onBack={() => {
                        setSelectedGroup(null)
                        setStep(STEPS_ENUM.SELECT_GROUP)
                    }}
                    isCanBack={!isEuVersion}
                    selectedGroup={selectedGroup}
                    onSelect={async data => await onSelect(data)}
                />
            )}
        </div>
    )
}

export default NewUserModal
