import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { CONDITIONS, hasPermission } from 'common/utils/permissions'
import { getAvatarText } from 'common/utils/getAvatarText.js'

import Modal from 'components/Modal/Modal'
import Toast from 'components/Toast/Toast.jsx'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import Mixpanel from 'common/services/Mixpanel'
import { API__ORGANIZATIONS } from 'api'

import { ORGANIZATIONS__SET_SELECTED_MEMBER, ORGANIZATIONS_REFRESH_MEMBERS } from 'store/actions'

import { isEmail } from 'utils/validator'
import { ALL_PROJECTS_MEMBER } from '../../utils/constants'

import { MODAL_TYPES } from '../ModalController'

import './ManageTeamModal.scss'

const ManageTeamModal = ({ onOpenModal, onClose }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {
        organizations: { selectedOrganizationId, selectedMember, memberList },
        userDetails,
    } = useSelector(state => ({ organizations: state.organizations, userDetails: state.user_details }))
    const [value, setValue] = useState('')
    const [removedMemberId, setRemovedMemberId] = useState(null)
    const [removingMemberId, setRemovingMemberId] = useState(null)

    const onCloseModal = () => {
        onClose()
        setValue('')
    }

    const onSendInvite = () => {
        const email = value.trim()
        if (!isEmail(email)) {
            Toast('error', {
                message: t('Must be email address'),
            })
            return
        }
        API__ORGANIZATIONS.INVITE_ORGANIZATION_MEMBERS(selectedOrganizationId, [email])
            .then(response => {
                const isNewlyRegistered = response[0].newlyRegistered
                if (isNewlyRegistered) onOpenModal(MODAL_TYPES.invitationSentByEmailModal)
                setValue('')
                dispatch(ORGANIZATIONS_REFRESH_MEMBERS())
                Mixpanel.track('Member invited', {})
            })
            .catch(error => {
                console.error(error)
                Toast('error', {
                    message: t(error.response.data.message),
                })
            })
    }

    const onRemoving = (userId, evt) => {
        evt.preventDefault()
        setRemovingMemberId(userId)
    }

    const onRemoveFromOrganization = userId => {
        API__ORGANIZATIONS.REMOVE_ORGANIZATION_MEMBER(selectedOrganizationId, userId)
            .then(() => {
                dispatch(ORGANIZATIONS_REFRESH_MEMBERS())
                if (selectedMember && selectedMember.userId === userId) {
                    dispatch(ORGANIZATIONS__SET_SELECTED_MEMBER(ALL_PROJECTS_MEMBER))
                }
            })
            .catch(error => {
                Toast('error', {})
                console.error(error)
            })
            .finally(() => {
                setRemovedMemberId(userId)
                setRemovingMemberId(null)
            })
    }

    return (
        <Modal isShowCloseIcon onClose={onCloseModal}>
            <div className="manage-team-modal">
                <h1 className="manage-team-modal__title">{t('Manage team')}</h1>
                {hasPermission(CONDITIONS.CAN_INVITE_IN_TEAM) && (
                    <div className="manage-team-modal__invite-block">
                        <input
                            className="manage-team-modal__invite-block-input"
                            type="email"
                            value={value}
                            placeholder={t('Email')}
                            onChange={evt => setValue(evt.target.value)}
                        />
                        <button
                            className="manage-team-modal__invite-block-button button"
                            data-variant="primary"
                            disabled={!value.length}
                            data-size="lg"
                            onClick={onSendInvite}
                        >
                            {t('Send invite')}
                        </button>
                    </div>
                )}

                <ul className="manage-team-modal__member-list">
                    {memberList.map(member => {
                        const isCurrentUser = member.userId === userDetails.id
                        return (
                            <li className="manage-team-modal__member" key={member.userId}>
                                <div className="manage-team-modal__member-info">
                                    <div className="manage-team-modal__member-name">
                                        <div
                                            className="manage-team-modal__member-icon"
                                            style={{
                                                backgroundColor: member.avatarUrl ? '#fff' : '#2990fb',
                                                backgroundImage: member.avatarUrl ? `url(${member.avatarUrl})` : 'none',
                                            }}
                                        >
                                            {!member.avatarUrl && getAvatarText(member)}
                                        </div>
                                        <p className="manage-team-modal__member-title">{`${member.name} ${
                                            isCurrentUser ? t('(You)') : ''
                                        }`}</p>
                                    </div>
                                    {!member.isApprove && (
                                        <div className="manage-team-modal__member-status">{t('Invitation sent')}</div>
                                    )}
                                </div>
                                <div className="manage-team-modal__member-actions">
                                    {isCurrentUser && (
                                        <div className="manage-team-modal__member-action disabled">{t('Owner')}</div>
                                    )}
                                    {!isCurrentUser && (
                                        <div
                                            className={`manage-team-modal__member-action ${
                                                removedMemberId === member.userId ? 'disabled' : 'active'
                                            }`}
                                            onClick={evt => onRemoving(member.userId, evt)}
                                        >
                                            {t('Remove')}
                                        </div>
                                    )}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            {removingMemberId && (
                <ConfirmationDialog
                    onClose={() => setRemovingMemberId(null)}
                    onAction={() => onRemoveFromOrganization(removingMemberId)}
                    data={{
                        headText: t('Remove this member?'),
                        noteText: t("The user's projects will be transferred to the team owner"),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}
        </Modal>
    )
}

export default ManageTeamModal
